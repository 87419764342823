

.navbar-links-area {
    display: flex;
    align-items: center;
}

.navbar-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.navbar-links li {
    display: inline-block;
    margin-right: 20px;
    color: #fff;
}

.menu-button {
    display: none; /* Oculta o botão de menu por padrão em dispositivos maiores */
}





/* <============================= DESKTOP =============================> */

.navbar {
    width: 100%;
    height: 80px;
    background-color: #005586;
    display: flex;
    align-items: center;
    z-index: 9999;
    transition: width 0.3s;
}

.navbar-content {
    width: 80%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

.navbar-logo {
    max-width: 180px;
}

.navbar-links-area {
}

.navbar-links {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.link {
    font-size: 15pt;
    font-weight: 600;
    margin-left: 20px;
}

span:hover{
    color: #b6b6b6;
    transition: 0.3s;
}

.link a{
    color: #fff;
    text-decoration: none;
}

.link:hover {
    color: #bdbdbd;
}

#login-button{
    padding: 5px 10px;
    border: 2px solid #fff;
    border-radius: 8px;
    /* background-color: #2eb4f1; */
}


#login-button:hover{
    background-color: #fff;
    border-color: #b6b6b6;
    transition: 0.5s;
}


/* INITIAL BANNER ============================> */
.initial-banner {
    width: 100%;
    height: 80vh;
    background-color: #005586;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.initial-banner-text-area {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.text-initial-banner {
    font-size: 20pt;
    font-weight: 200;
    color: #fff;
    border-right-width: 5px;
    border-right-color: #fff;
    border-right-style: solid;
    padding: 10px 20px 10px 0;
    margin-top: -100px;
}

.initial-banner-image-area {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.initial-banner-image {
    max-width: 350px;
}

.divided-container {
    width: 90%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 0 auto;
}

.divided-area {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* EXPLAIN PLATFORMS */
#explain-platform-section{
    margin-top: 150px;        
}

#explain-platform-text-area{
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 20;
}

.image-explain-platform {
    max-width: 500px;
}

.title-explain-platform {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 0 15px 0;
    border-radius: 15px;
    background-color: #d3d3d3;
}

.text-explain-platform {
    font-size: 15pt;
    font-weight: 300;
    padding-left: 20px;
    border-left-width: 2px;
    border-left-color: black;
    border-left-style: solid;
    margin-top: 30px;
}

.explain-platform-icons-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    margin-top: 20px;
}

.explain-platform-icons {
    padding: 10px 12px 10px 12px;
    background-color: #d3d3d3;
    border-radius: 50%;
    margin: 10px;
}

/* APRESENTACAO MENU DIGITAL */
#menu-digital-section{
    margin-top: 150px;
}

.image-present-digital-menu {
    width: 430px;
    height: 596px;
    border-radius: 30px;
    box-shadow: 0 2px 5px #333;
    background-image: url('../../../images/apresentacao-menu-digital-qr-code.PNG');
    background-position: center;
    background-repeat: initial;
}

/* TEXT-AREA */
#explain-menu-text-area{
    width: 90%; 
    align-self: flex-end;
}

.subtitle-explain-menu {
    width: 120px;
    font-size: 15pt;
    font-weight: 300;
    padding: 5px 15px 5px 15px;
    border-radius: 20px;
    background-color: #d3d3d3;
    align-self: flex-start;
}

.title-explain-menu {
    font-size: 50pt;
    margin-top: 20px;
    align-self: flex-start;
}

.text-explain-menu {
    width: 90%;
    align-self: 'flex-start';
    font-size: 19pt;
    font-weight: 300;
    margin-top: 30px;
}

.text-explain-menu b {
    font-weight: 600;
}

.statics-cards-area {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 150px auto 0 auto;
}

.title-statics {
    width: 60%;
    font-size: 23pt;
    font-weight: 300;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #000;
    padding-bottom: 10px;
}

#sphere-end-title-statics{
    width: 20;
    height: 20;
    border-radius: 50%;
    background-color: #000;
    position: relative; 
    top: -10;
    left: 60%;
}

/* CARDS ÁREA */
.cards-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.card {
    width: 210px;
    height: 250px;
    border: 2px solid #2eb4f1;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 6px;
}

.card:hover {
    position: relative;
    top: -15px;
}

.title-card {
    text-align: center;
    font-size: 16pt;
    font-weight: 700;
    margin-top: 30px;
}

.text-cart {
    font-size: 12pt;
    text-align: center;
    margin-top: 15px;
    font-weight: 300;
}

/*  EXPLAIN APP CONFIG =======================>*/
#explain-app-section{
    margin-top: 150px;
}

.image-mockup-app {
    width: 430px;
    height: 596px;
    border-radius: 20px;
    box-shadow: 0 3px 4px #666;
}

#explain-app-text-area{ 
    flex-direction: column;
    align-self: flex-start;
    justify-content: flex-start;
    margin-top: 50px; 
}

.title-explain-app {
    font-size: 40pt;
    font-weight: 700;
    text-transform: uppercase;
    align-self: flex-start;
}

.subtitle-explain-app {
    font-size: 14pt;
    font-weight: 300;
    align-self: flex-start;
}

.functionalities-area {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.container-explain-functionalities {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

.explain-functionalities-text {
    margin-left: 10px;
    font-weight: 300;
    font-size: 15pt;
}

.conclusion-explain-app {
    width: 80%;
    font-size: 16pt;
    font-weight: 300;
    margin-top: 30px;
    align-self: flex-start;
}

/* CONTACT AREA */

.contact-area {
    width: 80%;
    height: 150px;
    border-radius: 30px;
    background-color: #005586;
    box-shadow: 0 3px 4px #666;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 150px auto 0 auto;
}

.contact-cta-title-area {
    border-left-width: 3px;
    border-left-color: #fff;
    border-left-style: solid;
    padding-left: 15px;
    margin-left: 80px;
}

.title-cta {
    font-size: 30pt;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}

.subtitle-cta {
    font-size: 13pt;
    font-weight: 300;
    align-items: flex-start;
    color: #fff;
}

.subtitle-cta .title-cta {
    align-items: flex-start;
}

.cta-text-buttons-group{
    margin-right: 80px;
    display: flex;
    align-items: center;
}


.cta-text {
    font-size: 13pt;
    text-align: right;
    margin-right: 30px;
    font-weight: 300;
    color: #fff;
}

.whatsapp-button{
    padding: 10px 15px;
    background-color: rgb(2, 187, 2);
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 20px;
    border: none;
}

.whatsapp-button-text{
    display: none;
}

/* ABOUT US AREA */

.about-us-area {
    width: 80%;
    padding: 30px 20px 30px 20px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 3px 4px #666;
    margin: 100px auto 0 auto;
}

.about-us-title {
    font-size: 15pt;
    font-weight: 300;
    background-color: #d3d3d3;
    border-radius: 20px;
    padding: 10px 20px 10px 20px;
}

.about-us-text {
    font-size: 15pt;
    font-weight: 300;
    margin-top: 30px;
}

.logo-about-us {
    max-width: 150px;
    margin: 20px auto 0 auto;
}

/* BOTTOM MENU CONFIG */
.bottom-menu {
    width: 100%;
    height: 200px;
    position: relative;
    bottom: 0;
    background-color: #005586;
    margin-top: 100px;
    display: flex;
    align-items: center;
}

.bottom-menu-content {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 0 auto;
}

.bottom-logo {
    max-width: 250px;
}

.bottom-link {
    color: #fff;
    text-transform: uppercase;
    margin-top: 10px;
}

.bottom-link a {
    color: #fff;
    text-decoration: none;
}







/* <============================= MOBILE =============================> */

@media screen and (max-width: 768px) {
    .navbar {
        width: 100%;
        height: 80px;
        background-color: #005586;
        display: flex;
        align-items: center;
        z-index: 9999;
        transition: width 0.3s;
    }

    .navbar-content {
        width: 80%;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }

    .navbar-logo {
        max-width: 150px;
    }

    .navbar-links {
        width: 100%;
        display: none;
        position: absolute;
        top: 70px;
        left: 0;
        background-color: #2EB4F1;
        text-align: center;
    }

    .navbar-links.active {
        display: block;
        flex-direction: column;
    }

    .menu-button {
        display: block; 
        background: none;
        border: none;
        cursor: pointer;
    }

    .navbar-links-icon{
        display: block;
        cursor: pointer;
    }

    .navbar-links.active li {
        padding: 10px 20px;
    }

    .sidebar {
        left: -250px;
    }

    .sidebar.active {
        left: 0;
    }

    .link {
        font-size: 15pt;
        font-weight: 600;
        margin-left: 20px;
        color: #fff;
    }

    .link:hover {
        color: #bdbdbd;
    }

    #login-button{
        padding: 0;
        border: none;
        border-radius: 0;
        /* background-color: #2eb4f1; */
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: -250px;
        height: 100%;
        width: 250px;
        background-color: #333;
        padding-top: 60px;
        transition: left 0.3s ease;
    }
    
    .sidebar-nav {
        list-style: none;
        padding: 0;
    }
    
    .sidebar-nav li {
        padding: 10px;
    }
    
    .sidebar-nav a {
        text-decoration: none;
        color: #fff;
    }



    /* INITIAL BANNER ============================> */
    .initial-banner {
        width: 100%;
        height: 100vh;
        background-color: #005586;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .initial-banner-text-area {
        width: 90%;
        height: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .text-initial-banner {
        font-size: 20pt;
        font-weight: 200;
        color: #fff;
        border-bottom-width: 2px;
        border-bottom-color: #fff;
        border-bottom-style: solid;
        border-right-width: 0; 
        padding: 0 0 10px 0;
        margin-top: 40px;
    }

    .initial-banner-image-area {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .initial-banner-image {
        max-width: 250px;
    }



    /* COMMONS =============================>*/

    .divided-container {
        width: 90%;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto 0 auto;
    }

    .divided-area {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }



    /* EXPLAIN PLATFORMS */
    #explain-platform-section{
        margin-top: 50px;
        max-width: 500px;
    }

    .image-explain-platform {
        max-width: 400px;
    }

    #explain-platform-text-area{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
    }

    .title-explain-platform {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        padding: 15px 0 15px 0;
        border-radius: 40px;
        margin-top: 30px;
        background-color: #d3d3d3;
    }

    .text-explain-platform {
        font-size: 15pt;
        font-weight: 300;
        text-align: center;
        border: none;
        margin-top: 30px;
    }

    .explain-platform-icons-area {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: 20px;
    }

    .explain-platform-icons {
        padding: 10px 12px 10px 12px;
        background-color: #d3d3d3;
        border-radius: 50%;
        margin: 10px;
    }




    /* APRESENTACAO MENU DIGITAL */
    #menu-digital-section{
        margin-top: 50px;
        max-width: 500px;
    }

    .image-present-digital-menu {
        width: 90%;
        height: 596px;
        border-radius: 30px;
        box-shadow: 0 2px 5px #333;
        background-image: url('../../../images/apresentacao-menu-digital-qr-code.PNG');
        background-position: center;
        background-repeat: initial;
        margin-top: 50px;
    }


    /* TEXT-AREA */
    #explain-menu-text-area{
        width: 100%;
        text-align: center;
    }

    .subtitle-explain-menu {
        font-size: 15pt;
        font-weight: 300;
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
        background-color: #d3d3d3;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .title-explain-menu {
        font-size: 40pt;
        align-self: flex-start;
        margin-top: 50px;
    }

    .text-explain-menu {
        width: 100%;
        font-size: 17pt;
        font-weight: 300;
        margin-top: 30px;
        align-self: center;
    }

    .text-explain-menu b {
        font-weight: 600;
    }




    /* CARDS ÁREA ===============================>*/
    .statics-cards-area {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 150px auto 0 auto;
    }

    .title-statics {
        width: 80%;
        max-width: 400px;
        font-size: 18pt;
        font-weight: 300;
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: #000;
        padding-bottom: 10px;
        align-self: center  ;
    }

    #sphere-end-title-statics{
        /* width: 20;
        height: 20;
        border-radius: 50%;
        background-color: #000;
        position: relative; 
        top: -10px;
        left: 90%; */
        display: none;
    }

    
    /* CARDS */
    .cards-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;
    }

    .card {
        width: 80%;
        max-width: 230px;
        height: 250px;
        border: 2px solid #2eb4f1;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 6px;
    }


    .title-card {
        text-align: center;
        font-size: 16pt;
        font-weight: 700;
        margin-top: 30px;
    }

    .text-cart {
        font-size: 12pt;
        text-align: center;
        margin-top: 15px;
        font-weight: 300;
    }

    /* EXPLAIN APP ===============================================> */
    #explain-app-section{
        margin-top: 100px;
        max-width: 500px;
    }

    .image-mockup-app {
        width: 90%;
        max-width: 430px;
        height: 596px;
        border-radius: 20px;
        box-shadow: 0 3px 4px #666;
    }

    #explain-app-text-area{ 
        width: 90%;
        max-width: 500px;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        margin-top: 50px; 
    }

    .title-explain-app {
        font-size: 40pt;
        font-weight: 700;
        text-transform: uppercase;
        align-self: flex-start;
    }

    .subtitle-explain-app {
        font-size: 14pt;
        font-weight: 300;
        align-self: flex-start;
    }

    .functionalities-area {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
    }

    .container-explain-functionalities {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .explain-functionalities-text {
        margin-left: 10px;
        font-weight: 300;
        font-size: 15pt;
    }

    .conclusion-explain-app {
        width: 100%;
        font-size: 16pt;
        font-weight: 300;
        margin-top: 30px;
        align-self: flex-start;
    }

    /* CONTACT AREA */

    .contact-area {
        width: 100%;
        height: auto;
        border-radius: 0;
        background-color: #005586;
        box-shadow: 0 3px 4px #666;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 100px;
        padding: 20px 0 30px 0;
    }

    .contact-cta-title-area {
        border-bottom-width: 2px;
        border-bottom-color: #fff;
        border-bottom-style: solid;
        border-left-width: 0;
        padding: 0 0 5px 0;
        margin: 0;
    }
    
    .title-cta {
        margin: 0;
        font-size: 28pt;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
    }
    
    .subtitle-cta {
        margin: 0;
        margin-top: 20px;
        font-size: 13pt;
        font-weight: 300;
        align-items: center;
        color: #fff;
    }

    .subtitle-cta .title-cta {
        align-items: flex-start;
    }

    .cta-text-buttons-group{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px auto 0 auto;   
    }

    .buttons-cta-area {
        display: flex;
    }

    .cta-text {
        font-size: 15pt;
        text-align: center;
        font-weight: 300;
        color: #fff;
    }

    .cta-button {
        margin: 30px 20px 0 20px;
        background-color: transparent;
        border: none;
    }

    .whatsapp-button{
        margin-top: 30px;
    }

    .whatsapp-button-text{
        display: block;
        font-size: 15pt;
        font-weight: 700;
        color: #fff;
    }

    /* ABOUT US AREA */

    .about-us-area {
        width: 80%;
        /* max-width: 450px; */
        padding: 30px 20px 30px 20px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        box-shadow: 0 3px 4px #666;
        margin: 100px auto 0 auto;
    }

    .about-us-title {
        font-size: 15pt;
        font-weight: 300;
        background-color: #d3d3d3;
        border-radius: 20px;
        padding: 10px 20px 10px 20px;
    }

    .about-us-text {
        font-size: 15pt;
        font-weight: 300;
        margin-top: 30px;
    }

    .logo-about-us {
        max-width: 150px;
        margin: 30px auto 0 auto;
    }

    /* BOTTOM MENU CONFIG */
    .bottom-menu {
        width: 100%;
        height: 200px;
        position: relative;
        bottom: 0;
        background-color: #005586;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        margin-top: 100px;
        display: flex;
        align-items: center;
    }

    .bottom-menu-content {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        margin: 0 auto 0 auto;
    }

    .bottom-logo {
        max-width: 150px;
        margin-top: 30px;
    }

    .bottom-link {
        color: #fff;
        text-transform: uppercase;
        margin-top: 10px;
    }

    .bottom-link a {
        color: #fff;
        text-decoration: none;
    }
}


