
body{
    background-color: #d3d3d3;
}


.alert-box{
    width: 60%;
    max-width: 400px;
    height: auto;
    min-height: 180px;
    padding: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 20px;
    z-index: 9020;
    box-shadow: 0 2px 4px #000;
    overflow: hidden;
}


.children-area{
    min-height: 200px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 50px;
}

.title-box{
    font-size: 15pt;
}

.subtitle-page{
    font-size: 12pt;
    margin-top: 20px;
}


.box-alert-button{
    width: auto;
    height: 50px;
    padding: 15px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2EB4F1;
}



/* LOGO AREA ==========================> */

.image-area{
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    background-color: #005586;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-image{
    width: 150px;
    height: auto;
}



@media screen and (max-width: 480px){
    .alert-box{
        width: 80%;
    }
}