


    /* CHROME E SAFARI */
    ::-webkit-scrollbar {
      width: 12px;
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: #777;
      border-radius: 6px;
    }
  
    ::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 8px;
    }
  
    /* FIREFOX */
    /* {
      scrollbar-width: thin;
      scrollbar-color: #888 #f1f1f1;
    } */
  
    /* EDGE E INTERNET EXPLORER*/
    *::-ms-scrollbar {
      width: 12px;
    }
    
    *::-ms-scrollbar-thumb {
      background-color: #888;
      border-radius: 6px;
    }
    
    *::-ms-scrollbar-track {
      background-color: #c3c3c3;
    }

    
  